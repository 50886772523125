<template>
  <validation-observer ref="formTreatment">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[2].text }}</h3>
          <b-row class="mt-2">
            <b-col
              md="9"
            >
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="name"
                  >Nama Treatment</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="name"
                    disabled
                    :value="vars.name"
                    type="text"
                  />
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="kode"
                  >Kode Treatment</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="kode"
                    disabled
                    :value="vars.no"
                    type="text"
                  />
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="basic_price"
                  >Basic Price</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="basic_price"
                    disabled
                    :value="vars.unitPriceFormat"
                    type="text"
                  />
                </b-col>
              </b-row>

              <b-row
                class="my-1"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="note"
                  >Foto Treatment</label>
                </b-col>
                <b-col sm="8">
                  <div class="image-input">
                    <div
                      v-if="vars.files.length > 0"
                      class="items-wrapper"
                    >
                      <div
                        v-for="file, index in vars.files"
                        :key="index"
                        class="item-wrapper"
                      >
                        <b-img
                          :src="getUrlOfFile(file)"
                          class="mb-2"
                          alt="image-items"
                        />
                        <b-button
                          v-if="!isDetailPage"
                          class="btn-primary"
                          @click.prevent="removePhoto(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </b-button>
                      </div>
                    </div>
                    <b-img
                      v-else
                      :src="getUrlOfFile(vars.photo, true)"
                      class="mb-2 w-50"
                    />
                    <label
                      v-if="!isDetailPage"
                      for="file-input"
                    >
                      <input
                        id="file-input"
                        type="file"
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        hidden
                        multiple
                        @change="AddPhoto"
                      >
                      <feather-icon icon="PlusIcon" />
                      Upload Photo
                    </label>

                  </div>
                </b-col>
              </b-row>

              <b-row
                class="my-1"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="note"
                  >Deskripsi</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Catatan"
                    rules="required"
                  >
                    <b-form-textarea
                      id="note"
                      v-model="models.description"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="note"
                  >Durasi</label>
                </b-col>
                <b-col sm="8">
                  <b-row
                    align-v="baseline"
                  >
                    <b-col
                      class="col-6"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Jam"
                        rules="required|numeric"
                      >
                        <div class="d-flex align-items-center">
                          <b-form-input
                            v-model="models.durationHours"
                            type="number"
                            class="mr-1"
                            :disabled="isDetailPage"
                            :state="errors.length > 0 ? false:null"
                          />
                          <span> Jam</span>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      class="col-6"
                    ><validation-provider
                      #default="{ errors }"
                      name="Menit"
                      rules="required|numeric"
                    >
                      <div class="d-flex align-items-center">
                        <b-form-input
                          v-model="models.durationMinutes"
                          type="number"
                          class="mr-1"
                          :disabled="isDetailPage"
                          :state="errors.length > 0 ? false:null"
                        />
                        <span>Menit</span>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="selling_price"
                  >Selling Price</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="selling_price"
                    disabled
                    type="text"
                  />
                </b-col>
              </b-row> -->
              <b-row
                v-if="!isDetailPage"
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="tag"
                  >Raw Material</label>
                </b-col>
                <b-col sm="8">
                  <div
                    v-for="raw, index in models.rawMaterials"
                    :key="index"
                  >
                    <div class="d-flex">
                      <validation-provider
                        #default="{ errors }"
                        name="Bahan baku"
                        rules="required"
                        class="w-100"
                      >
                        <v-select
                          id="tag"
                          v-model="raw.accuRawMaterialId "
                          label="name"
                          placeholder="- Cari Bahan Baku -"
                          :options="masters.rawMaterials"
                          :reduce="option => option.id"
                        >
                          <template #no-options="{ search, searching, loading }">
                            Tidak ditemukan hasil pencarian
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <div
                        v-if="models.rawMaterials.length > 1 && index !== models.rawMaterials.length - 1"

                        class="ml-1"
                      />
                      <b-button
                        v-if="models.rawMaterials.length > 1 && index !== models.rawMaterials.length - 1"
                        variant="primary"
                        class="btn-min-width d-block m-auto rounded"
                        @click="manageRawMaterial(null, index)"
                      >
                        Hapus
                      </b-button>
                    </div>
                    <b-row
                      class="my-1"
                      align-v="baseline"
                    >
                      <b-col
                        class="col-5"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Unit"
                          rules="required"
                        >
                          <div class="d-flex align-items-center">
                            <span style="min-width: max-content;">Unit</span>
                            <b-form-input
                              v-model="raw.unit"
                              type="text"
                              class="ml-1"
                              :state="errors.length > 0 ? false:null"
                            />
                          </div>
                          <small class="text-danger d-flex justify-content-end">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col
                        class="col-7"
                      ><validation-provider
                        #default="{ errors }"
                        name="Max Usage"
                        rules="required|numeric"
                      >
                        <div class="d-flex align-items-center">
                          <span style="min-width: max-content;">Max Usage</span>
                          <b-form-input
                            v-model="raw.maxUsage"
                            type="number"
                            class="ml-1"
                            :state="errors.length > 0 ? false:null"
                          />
                        </div>
                        <small class="text-danger d-flex justify-content-end">{{ errors[0] }}</small>
                      </validation-provider>
                      </b-col>
                    </b-row>
                  </div>
                  <b-button
                    type="button"
                    variant="outline-primary"
                    class="btn-min-width rounded w-100"
                    @click="manageRawMaterial('add')"
                  >
                    Tambah Raw Material
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-else
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="selling_price"
                  >Raw Material</label>
                </b-col>
                <b-col sm="8">
                  <b-row
                    v-for="raw, index in vars.rawMaterials"
                    :key="index"
                  >
                    <b-col cols="8">
                      {{ raw.name }}
                    </b-col>
                    <b-col cols="2">
                      {{ raw.maxUsage }}
                    </b-col>
                    <b-col cols="2">
                      {{ raw.unit }}
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row
                v-if="!isDetailPage"
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="status"
                  >Status</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    v-if="isDetailPage"
                    :value="models.status"
                    :disabled="isDetailPage"
                    type="text"
                  />
                  <div
                    v-else
                    class="col-sm-6 d-flex justify-content-between"
                  >
                    <label class="radio-inline">
                      <input
                        type="radio"
                        :value="true"
                        name="status"
                      >
                      <span class="badge badge-success">Aktif</span>
                    </label>
                    <label class="radio-inline">
                      <input
                        type="radio"
                        :value="false"
                        name="status"
                      >
                      <span class="badge badge-danger">Tidak Aktif</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded mx-md-1 my-1 my-md-0"
              @click="$router.push({path: config.uri})"
            >
              Kembali
            </b-button>
            <b-button
              v-if="!isDetailPage"
              type="submit"
              variant="primary"
              class="btn-min-width rounded"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'

import {
  BCard, BRow, BCol, BButton, BImg, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, numeric, image,
} from '@validations'
import api from '@/utils/api'

// eslint-disable-next-line import/no-cycle
import { getUrlOfFile, uploadFile } from '@/utils/helpers'

export default {
  name: 'FormJasaInventory',
  metaInfo: {
    title: 'Form Jasa',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormTextarea,
    BButton,
    BImg,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        id: this.$route.params.id,
        description: '',
        durationHours: null,
        durationMinutes: null,
        detailItemImage: [],
        rawMaterials: [
          {
            accuRawMaterialId: '',
            unit: '',
            maxUsage: '',
          },
        ],
      },
      vars: {
        files: [],
        name: '',
        no: '',
        unitPriceFormat: '',
        rawMaterials: [],
      },
      masters: {
        rawMaterials: [],
      },
      // Functions
      getUrlOfFile,

      required,
      numeric,
      image,
    }
  },
  computed: {
    isDetailPage() {
      return this.$route.name === 'detail-jasa'
    },
  },
  created() {
    this.getById()
    this.getOptions()
  },
  methods: {
    AddPhoto(e) {
      const fileSizeLimit = 1024 * 1024 * 2 // limit 2 MB
      const { files } = e.target
      let exitLoop = false

      Array.from(files).forEach(file => {
        if (exitLoop) return
        if (file.size <= fileSizeLimit) {
          this.vars.files.push(file)
          e.target.value = ''
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed',
              text: 'Max file size is 2 MB',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          exitLoop = true
        }
      })
    },
    removePhoto(index) {
      this.vars.files = this.vars.files.filter((file, idx) => idx !== index)
    },
    manageRawMaterial(type, index) {
      if (type === 'add') {
        this.models.rawMaterials.push({
          accuRawMaterialId: '',
          unit: '',
          maxUsage: '',
        })
      } else {
        this.models.rawMaterials.splice(index, 1)
      }
    },
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.accuTreatment}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data

            this.vars.name = data.name
            this.vars.no = data.no
            this.vars.unitPriceFormat = data.unitPriceFormat
            this.vars.files = data.detailItemImage
            this.models.description = data.description
            this.models.durationHours = data.durationHours
            this.models.durationMinutes = data.durationMinutes

            if (data.rawMaterials.length > 0) {
              this.models.rawMaterials = []
              data.rawMaterials.forEach(raw => {
                this.models.rawMaterials.push({
                  accuRawMaterialId: raw.accuRawMaterialId,
                  unit: raw.unit,
                  maxUsage: raw.maxUsage,
                })

                this.vars.rawMaterials.push({
                  name: raw.material.name,
                  unit: raw.unit,
                  maxUsage: raw.maxUsage,
                })
              })
            }
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
    getOptions() {
      this.$axios.get(api.list_rawmaterials).then(res => {
        this.masters.rawMaterials = [...res.data.data]
      })
    },
    setValue() {
      this.$axios.put(`${api.accuTreatment}`, this.models)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success update treatment',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$router.push(this.config.uri)
        })
        .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
    },
    async save() {
      this.$refs.formTreatment.validate().then(async success => {
        if (success) {
          if (this.vars.files.length > 0) {
            this.$store.commit('app/UPDATE_SPINNER', true)

            for (let i = 0; i < this.vars.files.length; i++) {
              const url = typeof this.vars.files[i] === 'string' ? this.vars.files[i] : await uploadFile(this.vars.files[i])
              this.models.detailItemImage.push(url)
            }
            this.setValue()
          } else {
            this.setValue()
          }
        } else {
          window.scrollTo(0, 0)
        }
      })
    },

  },
}
</script>

<style scoped>
.items-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 20px 0;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.items-wrapper img:last-child {
  margin-right: 0;
}

.item-wrapper {
  position: relative;
  margin-right: 15px;
  height: 150px;
}

.item-wrapper img {
  flex: 0 0 auto;
  height: 100%;
  width: 12em;
  object-fit: contain;
}

.items-wrapper button {
  position: absolute;
  padding: 10px;
  border-radius: 100%;
  background: #B22A2E !important;
  top: -10px;
  right: -10px;
}
.items-wrapper::-webkit-scrollbar {
  height: 5px;
  border-radius: 15px;
}

.items-wrapper::-webkit-scrollbar-track {
  border-radius: 15px;    /* width of the entire scrollbar */
  background: lightgray;        /* color of the tracking area */
}

.items-wrapper::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid rgb(180, 180, 180);  /* creates padding around scroll thumb */
}
</style>
